import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const HeaderImage = () => (
  <StaticQuery
    query={graphql`
      query {
        file(relativePath: { regex: "/storage_header_no_shadow.png/" }) {
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        className="gatsby-box-shadow gatsby-rounded"
        alt="A screenshot of the CineSend application"
        fluid={data.file.childImageSharp.fluid}
      />
    )}
  />
)

export default HeaderImage
