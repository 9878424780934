import React from "react"
import Layout from "@app-components/layout/"
import Header from "@app-components/ui/header/top_bottom/"
import Feature from "@app-components/ui/feature/"
import Grid from "@app-components/ui/grid/"
import HeaderImage from "@page-components/products/files/features/media-delivery/header/"
import UploadImage from "@page-components/products/files/features/media-delivery/upload/"
import DeliveryImage from "@page-components/products/files/features/media-delivery/delivery/"
import AsperaImage from "@page-components/products/files/features/media-delivery/aspera/"
import HEADERS from "@data/headers/"

const CloudStorage = () => (
  <Layout getStartedButtons={["demo", "get-started"]} page="features/cloud-storage">
    <Header
      title="Cloud Storage &amp; Delivery"
      text="Easy, quick, and secure online storage and delivery, without file size limits."
      image={<HeaderImage />}
      secondaryNavigation={HEADERS.files}
    />
    <Feature
      title="Upload &amp; Store Anything"
      text="Use CineSend to store massive media files, small documents, and anything in between. Organize your files into projects, where you can create your own folder structure to keep things organized."
      icon="upload-white"
      image={<UploadImage />}
    />
    <Feature
      title="Digital File Delivery"
      text="Share individual files, entire folders, or cherrypick files between different folders to securely deliver your files with external parties. A full history will let you see when recipients have downloaded the files."
      reverse
      icon="send-white"
      image={<DeliveryImage />}
    />
    <Grid
      title=""
      columnWidth={6}
      items={[
        {
          title: "No File Size Limit",
          text:
            "CineSend has no file size limits. Multi-threaded uploads allow you to upload and download large files with ease. For really large files, using Aspera lets you pause and resume interrupted transfers.",
          _id: 1,
          icon: "grid-white"
        },
        {
          title: "Passwords and Link Expiry",
          text: `Help reduce link sharing by setting a password when you share files with an external recipient. You can also set up the link to expire after the first download, or on a specific date.`,
          _id: 2,
          icon: "lock-white"
        },
        {
          title: "Super Secure",
          text:
            "Data you upload to CineSend is encrypted, and your files can only be accessed by you or people you authorize. For highly sensitive content, use the Aspera options to encrypt your content during upload and download.",
          _id: 3,
          icon: "shield-white"
        },
        {
          title: "Format Agnostic",
          text:
            "You can upload any kind of file you like. Most common filetypes can be previewed in CineSend, including advanced video formats like ProRes, DCP, IMF, and MPEG-TS.",
          _id: 4,
          icon: "file-white"
        }
      ]}
    />
    <Feature
      title="Aspera Transfers Built-In"
      text="IBM's Aspera technology facilitates fast and reliable file transfers, and is built right into CineSend. Using Aspera also gives you the control to pause and resume, and ensures that your content remains encrypted during the transfer."
      icon="aspera-white"
      image={<AsperaImage />}
    />
  </Layout>
)

export default CloudStorage
